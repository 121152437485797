import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  mdiMonitorDashboard,
  mdiAccountCircleOutline,
  mdiHomeOutline,
  mdiAccountCogOutline,
  mdiMonitorScreenshot,
  mdiHexagonMultipleOutline, mdiTextBoxSearchOutline,
} from '@mdi/js';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UI54Icon, UI54Menu } from '@agpl/ui54kit';
import { capitalize } from 'lodash';
import { getMeIsRootOrAdmin } from '../../profile/selectors';

function SidebarMenu({
  collapsed,
}) {
  const { t } = useTranslation();
  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  const [selectedKey, setSelectedKey] = useState([]);

  const location = useLocation();

  const menuItems = useMemo(() => [
    {
      label: 'User flow',
      key: 'user_flow',
      type: 'group',
    },
    {
      key: '/profile',
      icon: <UI54Icon path={mdiAccountCircleOutline} size={1} />,
      label: <Link id="profileLink" to="/profile" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.profile', 'profile'))}
      </Link>,
    },
    meIsRootOrAdmin && {
      key: '/admin/start-page',
      icon: <UI54Icon path={mdiHomeOutline} size={1} />,
      label: <Link id="startPageLink" to="/admin/start-page" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.start_page', 'start page'))}
      </Link>,
    },
    // {
    //   label: capitalize(t('auth.pages.biome_info', 'biome info')),
    //   linkTo: '/biome-info',
    //   iconPath: mdiInformationOutline,
    //   onlyAdmin: true
    // },
    meIsRootOrAdmin && {
      key: '/admin/actors',
      icon: <UI54Icon path={mdiAccountCogOutline} size={1} />,
      label: <Link id="actorsLink" to="/admin/actors" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.actors', 'actors'))}
      </Link>,
    },
    meIsRootOrAdmin && {
      key: '/admin/public-interfaces',
      icon: <UI54Icon path={mdiMonitorScreenshot} size={1} />,
      label: <Link id="publicInterfacesLink" to="/admin/public-interfaces" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.public_interfaces', 'public interfaces'))}
      </Link>,
    },
    meIsRootOrAdmin && {
      key: '/admin/groups-priority',
      icon: <UI54Icon path={mdiHexagonMultipleOutline} size={1} />,
      label: <Link id="groupsPriorityLink" to="/admin/groups-priority" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.groups_priority', 'groups priority'))}
      </Link>,
    },
    meIsRootOrAdmin && {
      key: '/admin/logs',
      icon: <UI54Icon path={mdiTextBoxSearchOutline} size={1} />,
      label: <Link id="logsLink" to="/admin/logs" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.logs', 'logs'))}
      </Link>,
    },
    meIsRootOrAdmin && {
      label: 'Admin flow',
      key: 'admin_flow',
      type: 'group',
    },
    meIsRootOrAdmin && {
      key: '/admin/biome-dashboard',
      icon: <UI54Icon path={mdiMonitorDashboard} size={1} />,
      label: <Link id="biomeDashboardLink" to="/admin/biome-dashboard" className="ui54-menu-title-text">
        {capitalize(t('auth.pages.biome_dashboard', 'biome dashboard'))}
      </Link>,
    },
  ], [meIsRootOrAdmin]);

  const onClick = (e) => {
    setSelectedKey([e.key]);
  };

  useEffect(() => {
    menuItems.map((item) => {
      if (item.children) {
        item.children.map((childItem) => {
          const { key } = childItem;

          if (location.pathname.includes(key)) {
            setSelectedKey([key]);
          }
        });
      } else {
        const { key } = item;
        if (location.pathname.includes(key)) {
          setSelectedKey([key]);
        }
      }
    });
  }, [location, menuItems]);

  return (
    <UI54Menu
      inlineIndent={30}
      onClick={onClick}
      theme="light"
      mode="inline"
      selectedKeys={selectedKey}
      items={menuItems}
    />
  );
}

SidebarMenu.propTypes = {
  collapse: PropTypes.bool,
};

export default SidebarMenu;
