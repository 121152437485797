import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import {
  Button, Col, Row, Space, Switch, Tag, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UI54Table } from '@agpl/ui54kit';
import AntTable from '../../components/AntTable';
import TableTitleWrapper from '../../components/TableTitleWrapper';

import { capitalize } from '../../mainUtils';
import { isPermissionsFetching } from '../selectors';
import { getAllGroupsMap } from '../../actors/selectors';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';
import BaseSwitch from '../../_ui/BaseSwitch/BaseSwitch';

function PermissionsList({
  onSelectPermission,
  selectedPermissionUUID,
  onDeletePermission,
  onSwitchValue,
  permissionsType,
  onOverridePermission,
  dataTable,
  getDataTable,
  selectedPermissionTableType,
  actorType,
}) {
  const { t } = useTranslation();

  const fetching = useSelector(isPermissionsFetching);
  const allGroupsMap = useSelector(getAllGroupsMap);

  const isNotGroup = permissionsType === 'default' || (
    actorType !== 'group' && permissionsType === 'group'
  );

  const formatName = (title, data) => {
    const { permaction_uuid: uuid, actor_uuid: actor } = data;
    const getGroupName = () => _.get(allGroupsMap.get(actor), 'uinfo.group_name');

    return (
      <Row>
        <Col span={24} className="mb-1">
          <span className="mr-2">
            {title.trim()}
          </span>
          {isNotGroup && (
            <BaseTag className="tag-red ml-auto">
              {getGroupName()}
            </BaseTag>
          )}
        </Col>
        <Col span={24}>
          <span className="text-secondary text-xl">
            {uuid}
          </span>
        </Col>
      </Row>
    );
  };

  const formatWeight = (weight) => {
    const getWeight = () => {
      switch (weight) {
        case '-':
          return '';
        case 4294967297:
        case 4294967298:
        case 4294967299:
          return 'MAX';
        default:
          return weight;
      }
    };

    return (
      <Tooltip
        title={weight}
      >
        <span
          className="inline-block truncate"
          style={{ maxWidth: 75 }}
        >
          {getWeight()}
        </span>
      </Tooltip>
    );
  };

  const formatUnions = (unions, data) => {
    const { permaction_uuid: uuid } = data;

    return (
      <Space size={[4, 4]} wrap>
        {unions.map((item) => (
          <BaseTag
            key={`${uuid}_${item}`}
            className="tag-gray-light"
          >
            {item}
          </BaseTag>
        ))}
      </Space>
    );
  };

  const formatType = (type) => {
    const colors = {
      simple: 'gray',
      check: 'volcano',
    };

    return (
      <BaseTag className={`tag-${colors[type]}`}>
        {capitalize(type)}
      </BaseTag>
    );
  };

  const formatSwitchValue = (value, data) => {
    const { permaction_uuid: uuid } = data;

    if (isNotGroup) {
      return (
        <BaseTag
          // style={{ width: 38, height: 24 }}
          className={`flex justify-center items-center tag-${value ? 'green' : 'red'}`}
        >
          {value ? 'Allowed' : 'Denied'}
        </BaseTag>
      );
    }

    return (
      <BaseSwitch
        checkedChildren="Allowed"
        unCheckedChildren="Denied"
        checked={value}
        onChange={(value, e) => {
          e.stopPropagation();
          onSwitchValue(uuid, data, value);
        }}
      />
    );
  };

  const formatAction = (uuid) => {
    const isDelete = !isNotGroup;

    const getIsOverridden = () => {
      switch (permissionsType) {
        case 'actor':
          return false;
        case 'group':
          return dataTable.actor.has(uuid);
        case 'default':
          return dataTable.actor.has(uuid) || dataTable.group.has(uuid);
        default:
          return false;
      }
    };

    return (
      <BaseButton
        className={`button-${isDelete ? 'danger' : 'primary'}-outlined px-2`}
        size="small"
        disabled={getIsOverridden()}
        onClick={(e) => {
          e.stopPropagation();
          if (isDelete) {
            onDeletePermission(uuid);
          } else {
            onOverridePermission(uuid, permissionsType);
          }
        }}
      >
        {isDelete
          ? capitalize(t('auth.buttons.remove', 'delete'))
          : capitalize(t('auth.buttons.override', 'override'))}
      </BaseButton>
    );
  };

  const columns = [
    {
      dataIndex: 'title',
      key: 'title',
      title: capitalize(t('auth.headers.title', 'title')),
      textWrap: 'word-break',
      className: 'p-2 pl-4',
      sorter: (a, b) => _.get(a, 'title', '').localeCompare(_.get(b, 'title', '')),
      render: formatName,
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      title: (
        <TableTitleWrapper minWidth={60}>
          {capitalize(t('auth.headers.weight', 'weight'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      sorter: (a, b) => +_.get(a, 'weight', 0)
        - +_.get(b, 'weight', 0),
      render: formatWeight,
    },
    {
      dataIndex: 'unions',
      key: 'unions',
      title: (
        <TableTitleWrapper minWidth={120}>
          {capitalize(t('auth.headers.attributes', 'attributes/Unions'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      render: formatUnions,
    },
    {
      dataIndex: 'value',
      key: 'perm_switch',
      title: (
        <TableTitleWrapper minWidth={70}>
          {capitalize(t('auth.headers.value', 'value'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      sorter: (a, b) => _.get(b, 'value') - _.get(a, 'value'),
      render: formatSwitchValue,
    },
    {
      dataIndex: 'perm_type',
      key: 'perm_type',
      title: (
        <TableTitleWrapper minWidth={40}>
          {capitalize(t('auth.headers.type', 'type'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      sorter: (a, b) => _.get(a, 'perm_type', '').localeCompare(_.get(b, 'perm_type', '')),
      render: formatType,
    },
    {
      dataIndex: 'permaction_uuid',
      key: 'action',
      title: (
        <TableTitleWrapper minWidth={90}>
          {capitalize(t('auth.headers.action', 'action'))}
        </TableTitleWrapper>
      ),
      className: 'p-2 pr-4',
      render: formatAction,
    },
  ];

  const onRow = (data) => ({
    onClick: (e) => {
      e.stopPropagation();
      onSelectPermission(data, permissionsType);
    },
  });

  const rowSelection = {
    columnWidth: '100%',
    selectedRowKeys: selectedPermissionUUID && permissionsType === selectedPermissionTableType
      ? [selectedPermissionUUID]
      : [],
  };

  return (
    <Col
      span={24}
      className={`perms-${permissionsType}`}
    >
      <h4 className="perms-title">
        {capitalize(t(`auth.headers.permissions_${permissionsType}`, permissionsType))}
      </h4>
      <UI54Table
        dataSource={getDataTable(permissionsType)}
        onRow={onRow}
        loading={fetching}
        rowSelection={rowSelection}
        columns={permissionsType === 'group' ? columns : [columns[0], ...columns.slice(2)]}
        rowKey="permaction_uuid"
        disablePagination
      />
      {/* <AntTable */}
      {/*  data={getDataTable(permissionsType)} */}
      {/*  onRow={onRow} */}
      {/*  loading={fetching} */}
      {/*  rowSelection={rowSelection} */}
      {/*  columns={permissionsType === 'group' ? columns : [columns[0], ...columns.slice(2)]} */}
      {/*  rowKey="permaction_uuid" */}
      {/*  disablePagination */}
      {/* /> */}
    </Col>
  );
}

export default PermissionsList;

PermissionsList.propTypes = {
  actorType: PropTypes.string,
  dataTable: PropTypes.object.isRequired,
  getDataTable: PropTypes.func.isRequired,
  onDeletePermission: PropTypes.func.isRequired,
  onOverridePermission: PropTypes.func.isRequired,
  onSelectPermission: PropTypes.func.isRequired,
  onSwitchValue: PropTypes.func.isRequired,
  permissionsType: PropTypes.string.isRequired,
  selectedPermissionTableType: PropTypes.string.isRequired,
  selectedPermissionUUID: PropTypes.string,
};
