// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.02.08 || YYYY/MM/DD

import PropTypes from 'prop-types';
import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Typography,
  Descriptions,
  Row,
  Skeleton,
  Spin,
  Tag,
} from 'antd';
import { capitalize } from 'lodash';
import {
  mdiAccountDetailsOutline,
  mdiAccountGroupOutline, mdiApps,
} from '@mdi/js';
import Icon from '@mdi/react';
import dayjs from 'dayjs';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { UI54Tag } from '@agpl/ui54kit';
import { urlAPI } from '../../api/api';
import { getNameForActor, getCookie } from '../utils54origins';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseTag from '../../_ui/BaseTag/BaseTag';
import { ApiContext } from '../../api/ApiContextProvider';
import { getMeIsRootOrAdmin } from '../../profile/selectors';
import { COLORS } from './publicInterface54Origins/publicInterfaceIconsConstants54Origins';
import InfoBlock from '../../components/InfoBlock/InfoBlock';

const { Title } = Typography;

const translateForProfilePage = {
  birthday: ['birthday', 'день рождения'],
  created: ['account created', 'аккаунт создан'],
  email: ['e-mail', 'эл-почта'],
  firstName: ['first name', 'имя'],
  lastName: ['last name', 'фамилия'],
  missing: ['missing', 'отсутствует'],
  profile: ['profile', 'профиль'],
  userDetail: ['user detail', 'сведения о пользователе'],
  userGroups: ['user groups', 'группы юзера'],
  userServices: ['user services', 'сервисы юзера'],
  userInfo: ['user info', 'информация о пользователе'],
};

const profileStyle = {
  descriptionStyle: {
    color: '#1890ff',
  },
  profileDiv: {
    width: '100%',
    // padding: '25px',
  },
  profileH1: {
    color: '#242424',
  },
  skeletonStyle: {
    margin: 0,
    height: 24,
  },
};
const {
  descriptionStyle, profileDiv, profileH1, skeletonStyle,
} = profileStyle || {};

function ProfilePage54origins({
  editBtn,
  editComponent,
  typesForRequest,
  updateFromOuterComponent,
}) {
  const dispatch = useDispatch();
  const { i18n: { language } } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const [actorInfo, setActorInfo] = useState({});

  const { requestGetPermissionsDigest } = useContext(ApiContext);
  const [services, setServices] = useState([]);

  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  const requestGetOpenInterfaces = () => {
    axios({
      method: 'get',
      url: `${urlAPI}/get_public_interfaces/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getCookie('Auth'),
      },
    }).then(({ data }) => {
      setServices(data?.services);
    });
  };

  const {
    uuid,
    group_names: userGroups,
    created,
    uinfo: {
      first_name,
      last_name,
      birthday,
      email,
    } = {},
  } = actorInfo || {};

  console.log('actorInfo', actorInfo);

  const checkLangAndGetTranslate = (key, isCapitalize = true) => {
    const translate = translateForProfilePage[key][language === 'en' ? 0 : 1];
    return isCapitalize ? capitalize(translate) : translate;
  };

  const getActorInfo = () => {
    const [REQUEST, SUCCESS, FAILURE] = typesForRequest;

    setFetching(true);

    dispatch({
      type: REQUEST,
    });

    axios({
      method: 'get',
      url: `${urlAPI}/actor/me`,
      headers: {
        'Session-Token': getCookie('Auth'),
      },
    })
      .then(({ data }) => {
        setActorInfo(data?.actor);
        dispatch({
          type: SUCCESS,
          payload: data,
        });
        // meIsRootOrAdmin && requestGetPermissionsDigest(data?.actor.uuid).then((res) => {
        //   const transformArr = Object.keys(res).map((key) => (key));
        //
        //   setServices(transformArr.sort());
        // });
      })
      .catch((err) => {
        dispatch({
          type: FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {

  }, [uuid]);

  const descriptionItem = (descriptionText, keyForTranslate) => (
    <Descriptions.Item
      label={checkLangAndGetTranslate(keyForTranslate)}
      labelStyle={{ width: '33%' }}
    >
      {descriptionText ? (
        <span style={descriptionStyle}>
          {descriptionText}
        </span>
      ) : checkLangAndGetTranslate('missing')}
    </Descriptions.Item>
  );

  useEffect(() => {
    getActorInfo();
  }, [updateFromOuterComponent]);

  useEffect(() => {
    requestGetOpenInterfaces();
  }, []);

  return (
    <div
      style={profileDiv}
    >
      <Row>
        <Col
          span={24}
        >
          <h1
            style={profileH1}
          >
            {checkLangAndGetTranslate('profile')}
          </h1>
        </Col>
      </Row>
      <Row gutter={[24, 16]} className="mt-4">
        <Col span={14}>
          <Spin
            spinning={fetching}
          >
            <BaseCard>
              {editComponent || (
                <>
                  <Row>
                    <Col span={12}>
                      <Skeleton
                        loading={false}
                        paragraph={false}
                        title={{
                          width: '50%',
                          style: skeletonStyle,
                        }}
                        active
                        round
                      >
                        <Title level={5}>
                          {getNameForActor(actorInfo)}
                        </Title>
                      </Skeleton>
                    </Col>
                    <Col span={12} className="flex">
                      {editBtn}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="flex">
                      <span className="mr-2">
                        UUID:
                        {' '}
                      </span>
                      <Title
                        copyable
                        level={5}
                        type="secondary"
                      >
                        {uuid}
                      </Title>
                    </Col>
                  </Row>
                  <hr className="my-6" />
                  <InfoBlock
                    iconPath={mdiAccountDetailsOutline}
                    title={checkLangAndGetTranslate('userDetail')}
                  >
                    <Row>
                      <Col span={24}>
                        <Descriptions
                          bordered
                          column={1}
                        >
                          {descriptionItem(first_name, 'firstName')}
                          {descriptionItem(last_name, 'lastName')}
                          {descriptionItem(email, 'email')}
                          {descriptionItem(birthday ? dayjs(birthday).format('DD-MM-YYYY') : '', 'birthday')}
                          {descriptionItem(dayjs(created).format('DD-MM-YYYY | HH:mm'), 'created')}
                        </Descriptions>
                      </Col>
                    </Row>
                  </InfoBlock>
                  {services && (
                  <InfoBlock
                    title={checkLangAndGetTranslate('userServices')}
                    iconPath={mdiApps}
                    className="mt-4"
                  >
                    <Row gutter={[8, 8]}>
                      {services.map((service) => (
                        <Col key={service.id}>
                          <UI54Tag color={COLORS[service.icon_color] || COLORS.GRAY}>
                            {service.service_name}
                          </UI54Tag>
                        </Col>
                      ))}
                    </Row>
                  </InfoBlock>
                  )}
                  {userGroups && (
                    <InfoBlock
                      title={checkLangAndGetTranslate('userGroups')}
                      iconPath={mdiAccountGroupOutline}
                      className="mt-4"
                    >
                      <Row gutter={[8, 8]}>
                        {userGroups?.map((groupName) => (
                          <Col key={groupName}>
                            <BaseTag
                              color={groupName.toLowerCase() === 'admin' ? 'red' : 'blue'}
                            >
                              {groupName}
                            </BaseTag>
                          </Col>
                        ))}
                      </Row>
                    </InfoBlock>
                  )}
                </>
              )}
            </BaseCard>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default memo(ProfilePage54origins);

InfoBlock.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  iconPath: PropTypes.any,
  title: PropTypes.string,
};

ProfilePage54origins.propTypes = {
  editBtn: PropTypes.element,
  editComponent: PropTypes.any,
  typesForRequest: PropTypes.array,
  updateFromOuterComponent: PropTypes.any,
};
