import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Collapse, Row, Tag,
} from 'antd';
import { UI54Table } from '@agpl/ui54kit';
import { ApiContext } from '../../api/ApiContextProvider';
import useURLParams from '../../hooks/useURLParams';
import AntTable from '../../components/AntTable';
import InternalOrExternalTag from './InternalOrExternalTag';
import { dateFormat54origins } from '../../54origins/utils54origins';
import AntButtonWithMDI from '../../components/AntButtonWithMDI';
import ActorInfo from './infoForms/ActorInfo/ActorInfo';
import BaseTag from '../../_ui/BaseTag/BaseTag';

const { Panel } = Collapse;

function CardWithTable({
  arr,
  isAdmin,
  isRoot,
  isSpecAdmin,
}) {
  const { getURLParams, routeNavigateAndClearParams } = useURLParams();

  const {
    uuid: selectedService,
  } = getURLParams();

  const permsTag = <BaseTag color="purple">All permissions are available</BaseTag>;

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      className: 'p-2',
      title: 'Name',
      // render: formatName,
    },
    {
      dataIndex: 'actor_type',
      key: 'actor_type',
      className: 'p-2',
      title: 'Type',
      // render: formatName,
    },
    {
      dataIndex: 'internal_user',
      key: 'internal_user',
      className: 'p-2',
      title: 'Created',
      render: (cell) => <InternalOrExternalTag internal={cell} />,
    },
    {
      dataIndex: 'created',
      key: 'created',
      className: 'p-2',
      title: 'Created',
      render: (cell) => dateFormat54origins(cell).dateWithTime,
    },
    {
      dataIndex: 'uuid',
      key: 'uuid',
      className: 'p-2',
      render: (cell) => (
        (isAdmin || isRoot) ? permsTag : (
          <>
            {isSpecAdmin && permsTag}
            <AntButtonWithMDI
              label="Show permissions"
              className="button-primary-outlined ml-2"
              onClick={() => routeNavigateAndClearParams('/admin/actors/permissions', {
                uuid: cell,
                service: selectedService,
              })}
            />
            <AntButtonWithMDI
              label="Go to actor"
              className="button-primary-outlined ml-2"
              onClick={() => routeNavigateAndClearParams('/admin/actors/list', {
                uuid: cell,
                view: 'info',
              })}
            />
          </>
        )
      ),
    },
  ];
  return (
    <UI54Table
      columns={columns}
      dataSource={arr}
      useDefaultPagination
    />
    // <AntTable columns={columns} data={arr} />
  );
}

function ActorsWithPermsOnService() {
  const { requestGetActorsWithPermsOnService } = useContext(ApiContext);
  const { getURLParams, routeNavigateAndClearParams } = useURLParams();

  const { uuid } = getURLParams();

  const [tableData, setTableData] = useState([]);

  const {
    admins,
    groups,
    services,
    root,
    specific_service_admins,
    users,
  } = tableData;

  const onChange = (key) => {
    // console.log(key);
  };

  const onSuccessDelete = () => {
    routeNavigateAndClearParams('/');
  };

  const initFunc = () => {
    requestGetActorsWithPermsOnService(uuid).then((res) => {
      setTableData(res);
    });
  };

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <Row
      gutter={24}
    >
      <Col span={14}>
        <Collapse
          onChange={onChange}
          defaultActiveKey={6}
        >
          {root?.length !== 0
          && (
          <Panel header="Root" key={1}>
            <CardWithTable arr={root} isRoot />
          </Panel>
          )}
          {admins?.length !== 0 && (
          <Panel header="Admins" key={2}>
            <CardWithTable arr={admins} isAdmin />
          </Panel>
          )}
          {groups?.length !== 0 && (
          <Panel header="Groups" key={3}>
            <CardWithTable arr={groups} />
          </Panel>
          )}
          {services?.length !== 0 && (
          <Panel header="Services" key={4}>
            <CardWithTable arr={services} />
          </Panel>
          )}
          {specific_service_admins?.length !== 0 && (
            <Panel header="Specific service admins" key={5}>
              <CardWithTable arr={specific_service_admins} isSpecAdmin />
            </Panel>
          )}
          {users?.length !== 0 && (
          <Panel key={6} header="Users">
            {' '}
            <CardWithTable arr={users} />
          </Panel>
          )}
        </Collapse>
      </Col>
      <Col span={10}>
        <ActorInfo
          onSuccessDelete={onSuccessDelete}
          hideElements={['actorsPermsOnServiceBtn']}
          isCollapsed
        />
      </Col>
    </Row>
  );
}

export default ActorsWithPermsOnService;
