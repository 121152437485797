import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tabs } from 'antd';
import Icon from '@mdi/react';
import {
  mdiApps,
  mdiCertificateOutline, mdiFileDocumentArrowRightOutline,
  mdiInformationOutline,
  mdiKeyOutline,
  mdiRefresh,
  mdiShieldCheckOutline,
} from '@mdi/js';

import { UI54Card } from '@agpl/ui54kit';
import PageWrapper from '../components/PageWrapper';
import SyncServicesPage from '../syncServices/components/SyncServicesPage';
import BiomeInfoPage from './BiomeInfoPage';
import CertificatesPage from '../certificates/components/CertificatesPage';
import SecurityPolicyComponent from '../securityPolicy/components/SecurityPolicyPage';
import ServicesKeyPairs from '../actors/components/service/ServicesKeyPairs';
import ServicesSessions from '../actors/components/service/ServicesSessions';
import ServicesVersioning from '../actors/components/service/ServicesVersioning';

import { capitalizeAndTranslateMsg } from '../mainUtils';
import useURLParams from '../hooks/useURLParams';
import BaseCard from '../_ui/BaseCard/BaseCard';
import BaseIcon from '../_ui/BaseIcon/BaseIcon';

function BiomeDashboard() {
  const { setSearchParams, getURLParams } = useURLParams();

  const { tab } = getURLParams();

  const [activeKey, setActiveKey] = useState('synchronization');

  const getLabelAndTranslate = (label, iconPath, translatePath = '') => (
    <span>
      <BaseIcon path={iconPath} size={1} />
      <span className="ml-1">{capitalizeAndTranslateMsg(translatePath, label)}</span>
    </span>
  );

  const onTabClick = (key) => {
    setSearchParams({ tab: key });
  };

  const items = useMemo(() => (
    [
      {
        label: getLabelAndTranslate('Biome info', mdiInformationOutline, 'auth.messages.biome_info'),
        key: 'info',
        children: <BiomeInfoPage />,
      },
      {
        label: getLabelAndTranslate('Synchronization', mdiRefresh, '54origins.noun.synchronization'),
        key: 'synchronization',
        children: <SyncServicesPage />,
      },
      {
        label: getLabelAndTranslate('Services Keypairs', mdiKeyOutline, 'auth.messages.services_keypairs'),
        key: 'serviceskeypairs',
        children: <ServicesKeyPairs />,
      },
      {
        label: getLabelAndTranslate('services sessions', mdiApps, 'auth.messages.services_sessions'),
        key: 'servicessessions',
        children: <ServicesSessions />,
      },
      {
        label: getLabelAndTranslate('versioning', mdiFileDocumentArrowRightOutline, '54origins.noun.versioning'),
        key: 'versioning',
        children: <ServicesVersioning />,
      },
      {
        label: getLabelAndTranslate('SSL certificates', mdiCertificateOutline, 'auth.messages.ssl_certificates'),
        key: 'ssl',
        children: <CertificatesPage />,
      },
      {
        label: getLabelAndTranslate('Security policy', mdiShieldCheckOutline, 'auth.messages.security_policy'),
        key: 'secpolicy',
        children: <SecurityPolicyComponent />,
      },
    ]
  ), []);

  useEffect(() => {
    if (!tab) {
      setSearchParams({ tab: 'synchronization' });
    } else {
      setActiveKey(tab);
    }
  }, [tab]);

  return (
    <PageWrapper
      title={capitalizeAndTranslateMsg('auth.pages.biome_info', 'biome dashboard')}
    >
      <UI54Card>
        <Tabs
          onTabClick={onTabClick}
          defaultActiveKey="synchronization"
          activeKey={activeKey}
          destroyInactiveTabPane
          items={items}
        />
      </UI54Card>
    </PageWrapper>
  );
}

export default BiomeDashboard;
