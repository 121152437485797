import React, { Suspense, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  Route, Navigate, Outlet, RouterProvider, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';

import ErrorBoundary from '../containers/error/ErrorBoundary';
import StartPage from '../startPage/StartPage';
import ActorsPage from '../actors/components/ActorsPage';
import GroupsPriorityPage from '../groups/components/GroupsPriorityPage';
import LogsPage from '../logsView/components/LogsPage';
import InterfacesPage from '../publicInterfaces/components/InterfacesPage';
import BiomeDashboard from '../BIOME/BiomeDashboard';
import Login54Origins from '../54origins/components/Login54Origins';
import AuthProfileWrapper from '../components/AuthProfileWrapper';
import MainLayout from '../MainLayout/MainLayout';
import ActorsBreadCrumbs from '../actors/components/ActorsBreadCrumbs';
import PermissionsPage from '../permissions/components/PermissionsPage';
import ActorsWithPermsOnService from '../actors/components/ActorsWithPermsOnService';
import PermissionDigest from '../permissions/components/PermissionDigest';

import { getAccess, isProfileFetching } from '../profile/selectors';
import { AuthHelper } from '../auth/utils';
import ApiContextProvider from '../api/ApiContextProvider';
import configureStore from '../store';
import useURLParams from '../hooks/useURLParams';
import GitVersion54origins from '../54origins/components/GitVersion54origins';

import { urlAPI } from '../api/api';
import { theme } from './theme';

export const { store } = configureStore();

function LoginRouteWrapper() {
  return (
    AuthHelper.isAuthorised() ? <Navigate to="/" /> : <Login54Origins url={urlAPI} serviceName="AUTH54" />
  );
}

function CheckAuthToken() {
  return (
    AuthHelper.isAuthorised() ? <Outlet /> : <Navigate to="/login" replace />
  );
}

function PrivateRouteNext({
  isAdminRoute,
  children,
}) {
  // const userIsAdmin = useSelector(isAdmin);
  // const authConfigFetching = useSelector(getAuthConfigFetching);

  const access = useSelector(getAccess);
  // const isFetching = useSelector(isProfileFetching);

  // if (AuthHelper.isAuthorised() && isFetching) {
  //   return <Loader />;
  // }

  if (!AuthHelper.isAuthorised()) {
    return <Navigate to="/login" replace />;
  }

  if (isAdminRoute && !access) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function GoBack() {
  const { navigate, currentLocation } = useURLParams();

  console.log('currentLocation', currentLocation);

  useEffect(() => {
    // navigate(-1);
  }, []);
}

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/login" element={<LoginRouteWrapper />} />
        <Route
          path="/"
          element={(
            <PrivateRouteNext>
              <MainLayout
                fixedHeader
                headerHeight={70}
                fixedSidebar
                sidebarWidth={240}
                fixedFooter
                footerHeight={70}
              />
            </PrivateRouteNext>
        )}
          errorElement={<ErrorBoundary />}
        >
          <Route index path="/" element={<Navigate to="/profile" />} />
          <Route path="admin">
            <Route index element={<Navigate to="/profile" />} />

            <Route path="start-page" element={<StartPage />} />
            <Route
              path="actors"
              element={(<ActorsBreadCrumbs />)}
            >
              <Route index element={<Navigate to="/admin/actors/list" />} />
              <Route path="list" element={<ActorsPage />} />
              <Route path="permissions" element={<PermissionsPage />} />
              {/* <Route path="perms-digest" element={<PermissionDigest />} /> */}
              <Route path="perms-on-service" element={<ActorsWithPermsOnService />} />
            </Route>
            <Route path="biome-dashboard" element={<BiomeDashboard />} />
            <Route path="logs" element={<LogsPage />} />
            <Route path="public-interfaces" element={<InterfacesPage />} />
            <Route path="groups-priority" element={<GroupsPriorityPage />} />
          </Route>
          <Route path="profile" element={<AuthProfileWrapper />} />
          <Route path="version" element={<GitVersion54origins />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </>,
    ),
  );

  return (
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <ApiContextProvider>
          <Suspense fallback={<Spin />}>
            <RouterProvider router={router} />
          </Suspense>
        </ApiContextProvider>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
