import React, {
  memo, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Col, Flex, Row,
} from 'antd';
import Icon from '@mdi/react';
import { mdiReload } from '@mdi/js';
import { useSelector } from 'react-redux';
import { getAllActors } from '../../selectors';

import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';

import { ApiContext } from '../../../api/ApiContextProvider';
import { antNotification } from '../../../mainUtils';
import useCustomState from '../../../hooks/useCustomState';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../../_ui/BaseButton/BaseButton';

const ServiceVersionBtn = memo(({
  uuid,
  checkServiceVersion,
  outerUUID,
  serviceBtnCallback,
}) => {
  // console.log('RENDER uuid', uuid)
  const {
    changeState,
    setToggle,
    toggle,
    version,
    description,
    error,
  } = useCustomState({
    version: '',
    description: '',
    error: null,
  });

  const checkVersion = () => {
    setToggle();
    changeState({ error: null });

    return checkServiceVersion(uuid)
      .then((res) => {
        const { version, description } = res || {};

        setToggle();
        antNotification.success('Success');

        changeState({
          version,
          description,
        });
      })
      .catch((error) => {
        changeState({ error: error?.message });
        setToggle();
      })
      .finally(() => {
        if (serviceBtnCallback) {
          serviceBtnCallback(uuid);
        }
      });
  };

  useEffect(() => {
    if (outerUUID === uuid) {
      checkVersion().then(() => {}).catch(() => {});
    }
  }, [outerUUID]);

  return (
    <Flex gap={10}>
      <BaseButton
        className={`${!error && 'button-primary-outlined'}`}
        size="small"
        danger={!!error}
        disabled={toggle}
        onClick={checkVersion}
      >
        {toggle && 'Pending...'}
        {!toggle && !error && !version && 'Check version'}
        {!toggle && version
                  && (
                  <div>
                    <BaseIcon
                      className="ml-1"
                      path={mdiReload}
                      size={1}
                    />
                    <span>Reload</span>
                  </div>
                  )}
        {!toggle && error && (
        <>
          <BaseIcon
            className="ml-1"
            path={mdiReload}
            size={1}
          />
          <span>Error</span>
        </>
        )}
      </BaseButton>
      <div>
        {version && (
          <div>
            {'version: '}
            {version}
          </div>
        )}
        {error && (
        <div
          className="text-red"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {error}
        </div>
        )}
        {description && (
        <div>
          {'description: '}
          {description}
        </div>
        )}
      </div>
    </Flex>
  );
});

function ServicesVersioning() {
  const { checkServiceVersion } = useContext(ApiContext);

  const serviceVersioningFetching = useSelector((state) => state.actors.serviceVersioning.fetching);
  const allServices = useSelector(getAllActors);

  // console.log('allServices', allServices)

  const [currentService, setCurrentService] = useState(null);
  const [callbackServiceUUID, setCallbackServiceUUID] = useState(null);

  const checkAllVersions = () => {
    setCallbackServiceUUID(null);

    if (currentService === null) {
      setCurrentService(allServices[0].uuid);
    } else {
      const nextServiceIndex = allServices.findIndex((el) => el.uuid === currentService) + 1;
      const nextServiceUUID = allServices[nextServiceIndex]?.uuid || null;

      setCurrentService(nextServiceUUID);
    }
  };

  const actionFormatter = (cell, row) =>
    // console.log('celll', cell)
    (
      <ServiceVersionBtn
        uuid={cell}
        outerUUID={currentService}
        checkServiceVersion={checkServiceVersion}
        serviceBtnCallback={setCallbackServiceUUID}
      />
    );
  const actionForVersion = [{
    dataIndex: 'uuid',
    key: 'actions',
    // className: 'flex p-2 pr-4',
    title: '',
    ellipsis: true,
    colSpan: 2,
    onCell: (_, index) => ({ colSpan: 2 }),
    render: actionFormatter,
    shouldCellUpdate: (record) => record.uuid === currentService || currentService === null,
  }];

  useEffect(() => {
    if (currentService && currentService === callbackServiceUUID) {
      checkAllVersions();
    }
  }, [currentService, callbackServiceUUID]);

  return (
    <>
      <Row>
        <Col span={24}>
          <BaseButton
            type="primary"
            size="small"
            onClick={checkAllVersions}
            disabled={serviceVersioningFetching}
          >
            {serviceVersioningFetching ? 'Pending...' : 'Check all service versions'}
          </BaseButton>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ColumnsWrapperForBasicActorList
            getActorTypes={['service']}
            typeOfColumns="servicesVersions"
            additionalColumns={actionForVersion}
          />
        </Col>
      </Row>
    </>
  );
}

export default ServicesVersioning;
