import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Card, Col, Row, Spin, Tag, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiInformationOutline,
} from '@mdi/js';
import { get, capitalize } from 'lodash';

import { UI54Table } from '@agpl/ui54kit';
import AntTable from '../../components/AntTable';
import LogsInfoComponent from './LogsInfoComponent/LogsInfoComponent';
import FilterLogs from './FilterLogs';
import TagForLogComponent from './TagForLogComponent';
import PageWrapper from '../../components/PageWrapper';
import AntButtonWithMDI from '../../components/AntButtonWithMDI';

import { getLocale } from '../../locale/selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import { getNameForActor } from '../../54origins/utils54origins';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';
import BaseTag from '../../_ui/BaseTag/BaseTag';

function LogsPage() {
  const { t } = useTranslation();
  const { requestGetAllActors, requestGetAllGroups } = useContext(ApiContext);

  const locale = useSelector(getLocale) || 'en';

  const [logsData, setLogsData] = useState([]);
  const [currentView, setCurrentView] = useState('');
  const [currentLog, setCurrentLog] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const offset = pageLimit * (currentPage - 1);

  const dataSource = useMemo(
    () => logsData?.slice((currentPage - 1) * pageLimit, currentPage * pageLimit),
    [currentPage, pageLimit, JSON.stringify(logsData)],
  );

  const switchView = (view) => {
    setCurrentView(view || '');
  };

  const formatTime = (cell) => {
    const getTime = dayjs(cell).locale(locale).format('YYYY-MM-DD hh:mm:ss');

    return (
      <span className="text-primary text-size-4">
        {getTime}
      </span>
    );
  };

  const formatAction = (cell) => <TagForLogComponent typeOfTag={cell} />;

  const formatType = (row) => {
    const actorType = get(row, 'actor.actor_type') || get(row, 'actor_type') || get(row, 'previous.actor_type');

    const title = capitalize(t(
      `auth.headers.${actorType}`,
      actorType,
    ));

    return (
      <BaseTag className={`tag-${actorType}`}>
        {title}
      </BaseTag>
    );
  };

  const formatUpdater = (cell, row) => (
    <Tooltip
      title={capitalize(t(
        'auth.buttons.detail_actor_info',
        'click for detailed information about the actor',
      ))}
      placement="left"
    >
      <AntButtonWithMDI
        onClick={() => {
          setCurrentLog(row);
          switchView('UpdaterInfo');
        }}
        className="button-primary-link"
        size="small"
        label={getNameForActor(cell)}
        pathForMdi={mdiInformationOutline}
        iconClassName="mr-1"
      />
    </Tooltip>
  );

  const formatViewButton = (uuid, log) => (
    <BaseButton
      className="button-primary-outlined"
      size="small"
      onClick={() => {
        setCurrentLog(log);
        switchView('info');
      }}
    >
      {capitalize(t('auth.buttons.detail', 'detail'))}
      <BaseIcon path={mdiChevronRight} size={1} className="ml-1" />
    </BaseButton>
  );

  const columns = [
    {
      dataIndex: 'created',
      key: 'created',
      className: 'p-1',
      title: capitalize(t('auth.headers.time', 'time')),
      render: formatTime,
    },
    {
      title: capitalize(t('auth.headers.action', 'action')),
      dataIndex: 'action',
      key: 'action',
      className: 'p-3',
      render: formatAction,
    },
    {
      title: capitalize(t('auth.headers.actor', 'actor')),
      dataIndex: 'actor',
      key: 'actor',
      className: 'p-3',
      ellipsis: true,
      render: (cell, row) => (
        <span className="colorTextGrey">
          {getNameForActor(get(row, 'action') === 'delete' ? get(row, 'previous') : cell)}
        </span>
      ),
    },
    {
      title: capitalize(t('auth.headers.type', 'type')),
      dataIndex: 'type',
      key: 'type',
      className: 'p-3',
      render: (cell, row) => formatType(row),
    },
    {
      title: capitalize(t('auth.headers.by', 'by')),
      dataIndex: 'updater',
      key: 'updater',
      className: 'p-3',
      ellipsis: true,
      render: formatUpdater,
    },
    {
      dataIndex: 'uuid',
      key: 'uuid',
      className: 'p-3',
      title: '',
      align: 'right',
      render: formatViewButton,
    },
  ];

  useEffect(() => {
    requestGetAllActors();
    requestGetAllGroups();
  }, []);

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const onShowSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageLimit(size);
  };

  return (
    <PageWrapper title={capitalize(t('auth.pages.logs', 'logs'))}>
      <Row gutter={[24, 16]}>
        <Col span={14}>
          <BaseCard
            loading={false}
          >
            <div className="mb-6">
              <FilterLogs
                setLogsData={setLogsData}
              />
            </div>
            <Spin spinning={false}>
              <div className="overflow-auto">
                <UI54Table
                  dataSource={dataSource}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    total: logsData.length,
                    current: currentPage,
                    pageSize: pageLimit,
                    pageSizeOptions: [25, 50],
                    onShowSizeChange,
                    onChange: onChangePagination,
                  }}
                  tableLayout="auto"
                />
              </div>
              {/* <AntTable */}
              {/*  data={logsData} */}
              {/*  columns={columns} */}
              {/*  rowKey="id" */}
              {/* /> */}
            </Spin>
          </BaseCard>

        </Col>
        {(
          currentView && currentLog
        ) && (
          <Col span={10}>
            <LogsInfoComponent
              logData={currentLog}
              currentViewInfoFromEntity={currentView === 'info'}
                    // formatAction={formatAction}
              formatType={formatType}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  );
}

export default LogsPage;
