import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Col, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiPlusCircle,
} from '@mdi/js';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { antNotification, capitalize } from '../../mainUtils';

import InterfaceCreate from './InterfaceCreate';
import InterfaceInfo from './InterfaceInfo';
import PageWrapper from '../../components/PageWrapper';
import ListOfPublicInterfacesTable from './ListOfPublicInterfacesTable';

import { getInterfacesList } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import BaseCard from '../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../_ui/BaseButton/BaseButton';

function InterfacesPage() {
  const { t } = useTranslation();
  const {
    requestGetAllInterfaces,
    requestGetOpenInterfaces,
    requestUpdateInterface,
  } = useContext(ApiContext);

  const allInterfaces = useSelector(getInterfacesList);

  const [searchValue, setSearchValue] = useState('');
  const [interfaceID, setInterfaceID] = useState(null);
  const [currentView, setCurrentView] = useState('');
  const [tableData, setTableData] = useState([]);

  const isCreateView = currentView === 'create';

  const switchView = (view, id) => {
    setCurrentView(view || '');
    setInterfaceID(id || null);
  };

  const getAllInterfaces = () => new Promise((resolve) => {
    requestGetAllInterfaces().then((response) => {
      requestGetOpenInterfaces().then();
      resolve(_.get(response, 'public_interface', []));
    });
  });

  const onSearchInterface = (value) => {
    if (value) {
      setTableData(
        allInterfaces
          // .filter((item) => item?.service_name.match(new RegExp(value.replace(/\\/g, '\\\\'), 'g'))),
          .filter((item) => item?.service_name?.toLowerCase().includes(value)),
      );
    } else {
      setTableData(allInterfaces);
    }
  };

  const resetView = () => switchView();

  const updateDisplay = (row) => {
    const data = {
      ...row,
      display_service: !row.display_service,
    };

    requestUpdateInterface(data).then(() => {
      antNotification.success(
        capitalize(t('auth.notifications.updated_successfully', 'updated successfully')),
      );
      getAllInterfaces().then();
    });
  };

  const afterDelete = () => {
    resetView();
    getAllInterfaces().then();
  };

  const rowSelection = interfaceID ? [_.toString(interfaceID)] : [];

  const onRow = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      switchView('info', row.id);
    },
  });

  const afterCreate = (response) => {
    const id = _.get(response, 'public_interface.id');
    switchView('info', id);
    getAllInterfaces().then();
  };

  const initFunc = () => {
    getAllInterfaces().then();
  };

  useEffect(() => {
    onSearchInterface(searchValue);
  }, [searchValue, allInterfaces]);

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <PageWrapper
      title={capitalize(t('auth.pages.public_interfaces', 'public interfaces'))}
    >
      <Row gutter={[24, 16]}>
        <Col span={14}>
          <BaseCard className="border-blue">
            <Row gutter={[10, 10]}>
              <Col
                className="flex items-center"
                order={0}
              >
                <h4 className="header-primary mb-0 mr-4">
                  {capitalize(t(
                    'auth.headers.interfaces',
                    'interfaces',
                  ))}
                </h4>
              </Col>
              <Col
                className="flex items-center"
                span={8}
              >
                <Input.Search
                  allowClear
                  onSearch={setSearchValue}
                  className="w-full"
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={capitalize(t(
                    'auth.placeholders.search_interface',
                    'enter interface name',
                  ))}
                />
              </Col>
              <Col
                className="flex ml-auto flex-wrap justify-end"
              >
                {interfaceID && (
                  <BaseButton
                    className="flex-auto button-warning-outlined mr-2"
                    onClick={resetView}
                  >
                    {capitalize(t('auth.buttons.reset', 'reset'))}
                  </BaseButton>
                )}
                <BaseButton
                  className={isCreateView
                    ? 'button-warning-outlined'
                    : 'button-primary-outlined'}
                  onClick={() => {
                    if (isCreateView) {
                      resetView();
                    } else {
                      switchView('create');
                    }
                  }}
                >
                  <BaseIcon
                    path={mdiPlusCircle}
                    size={1}
                    className="slow mr-2"
                    rotate={isCreateView ? 765 : 0}
                  />
                  {isCreateView ? (
                    capitalize(t('auth.buttons.cancel', 'cancel'))
                  ) : (
                    capitalize(t('auth.buttons.create_interface', 'create interface'))
                  )}
                </BaseButton>
              </Col>
            </Row>
            <hr className="my-6" />
            <Row>
              <Col span={24}>
                <div className="overflow-auto">
                  <ListOfPublicInterfacesTable
                    tableData={tableData}
                    selection={rowSelection}
                    onRow={onRow}
                    onSuccessDeleteCallback={afterDelete}
                    updateDisplay={updateDisplay}
                  />
                </div>
              </Col>
            </Row>
          </BaseCard>
        </Col>
        {currentView === 'create' && (
          <Col span={10}>
            <InterfaceCreate
              resetView={resetView}
              onSuccessCreate={afterCreate}
              editMode
            />
          </Col>
        )}
        {currentView === 'info' && interfaceID && (
          <Col span={10}>
            <InterfaceInfo
              interfaceID={interfaceID}
              onSuccessUpdate={getAllInterfaces}
              onSuccessDelete={afterDelete}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  );
}

export default InterfacesPage;
