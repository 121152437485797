import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';

import cn from 'classnames';
import { Flex, Layout } from 'antd';
import { UI54Footer, UI54Sidebar } from '@agpl/ui54kit';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header';
import SidebarComponent from '../components/SidebarComponent/SidebarComponent';
import FooterComponent from '../components/Footer/Footer';

import { getAccess, getMeIsRootOrAdmin, isProfileFetching } from '../profile/selectors';
import { AuthHelper } from '../auth/utils';
import { ApiContext } from '../api/ApiContextProvider';
import { ActorsConstants } from '../actors/constants/actionTypes';
import useURLParams from '../hooks/useURLParams';
import './MainLayout.scss';
import SidebarMenu from '../components/SidebarMenu/SidebarMenu';
import { VersionLabel } from '../54origins/components/GitVersion54origins';

function MainLayout({
  fixedHeader,
  headerHeight,
  fixedSidebar,
  sidebarWidth,
  fixedFooter,
  footerHeight,
}) {
  const access = useSelector(getAccess);
  const isFetching = useSelector(isProfileFetching);
  const { currentLocationPathname, currentLocation } = useURLParams();
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();

  const {
    requestGetProfile, requestGetMyPermissions, requestAbout, requestGetAllGroups,
  } = useContext(ApiContext);

  const pathname = window.location.href;

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  const toggleMenu = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);

  useEffect(() => {
    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetProfile();
      requestGetMyPermissions();
      requestAbout();

      const defaultGroupsNames = { uinfo: { group_name: ['ADMIN', 'DEFAULT', 'BAN'] } };
      const defaultGroupsConstants = [
        ActorsConstants.GET_DEFAULT_GROUPS_REQUEST,
        ActorsConstants.GET_DEFAULT_GROUPS_SUCCESS,
        ActorsConstants.GET_DEFAULT_GROUPS_FAILURE,
      ];

      if (meIsRootOrAdmin) {
        requestGetAllGroups(defaultGroupsNames, defaultGroupsConstants);
      }
    }
  }, [AuthHelper.isAuthorised(), access, meIsRootOrAdmin]);

  // useEffect(() => {
  //   console.log('prev', localStorage.getItem('location'))
  //   console.log('currentLocation', currentLocation)
  //   localStorage.setItem('location', currentLocation)
  //
  // }, [JSON.stringify(currentLocation)])

  return (
    <Layout className={cn('min-h-screen', { 'h-screen': fixedHeader })}>
      {AuthHelper.isAuthorised() && (
      <Header
        toggleMenu={toggleMenu}
        menuOpen={collapsed}
        fixed={fixedHeader}
        headerHeight={headerHeight}
        sidebarWidth={sidebarWidth}
      />
      )}
      <Layout
        hasSider
      >
        {AuthHelper.isAuthorised() && (
        <UI54Sidebar
          width={240}
          collapsed={collapsed}
          fixed={fixedSidebar}
          bordered
          style={{
            top: fixedSidebar ? headerHeight ? `${headerHeight}px` : 70 : 0,
            height: fixedSidebar ? `calc(100vh - ${headerHeight || '70'}px)` : 'auto',
          }}
        >
          <SidebarMenu />
        </UI54Sidebar>
        )}

        <Layout>
          <Layout.Content
            className={cn('p-6 flex-col', { 'overflow-auto': fixedHeader })}
          >
            <Outlet />
          </Layout.Content>

          {AuthHelper.isAuthorised() && (
          <UI54Footer
            fixed={fixedFooter}
          >
            <Flex justify="center" align="center" className="w-full" gap={10}>
              <div>
                <span>
                  {capitalize(t('auth.headers.copyright', 'copyright'))}
                  {' '}
                  54Origins
                </span>
                <span className="px-1">&#169;</span>
                <span>{new Date().getFullYear()}</span>
              </div>
              <div>
                <span className="ml-12">
                  {process.env.NODE_ENV === 'production' && <VersionLabel />}
                  {' '}
                </span>
              </div>
            </Flex>
          </UI54Footer>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
