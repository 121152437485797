import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import Icon from '@mdi/react';
import { mdiKey, mdiReload } from '@mdi/js';
import {
  Button, Col, Flex, Row,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';

import { ApiContext } from '../../../api/ApiContextProvider';
import { antNotification } from '../../../mainUtils';
import {
  getCheckedServicesKeyPairs,
  getCheckedServicesKeyPairsError,
  getCheckedServicesKeyPairsUUID,
} from '../../reducers/servicesKeyPairsReducer';
import { ActorsConstants } from '../../constants/actionTypes';
import BaseIcon from '../../../_ui/BaseIcon/BaseIcon';
import BaseButton from '../../../_ui/BaseButton/BaseButton';

function ServicesKeyPairsBtn({ uuid }) {
  const dispatch = useDispatch();

  const { requestCheckServiceKeyPair } = useContext(ApiContext);

  const checkedServicesKeyPairs = useSelector(getCheckedServicesKeyPairs);
  const checkedServicesKeyPairsUUID = useSelector(getCheckedServicesKeyPairsUUID);
  const checkedServicesKeyPairsError = useSelector(getCheckedServicesKeyPairsError);

  const [fetching, setFetching] = useReducer((state) => !state, false);
  const [error, setError] = useState(null);
  const [serviceData, setData] = useState(null);

  const checkServicePair = () => {
    setFetching();
    setError(null);

    requestCheckServiceKeyPair(uuid)
      .then((data) => {
        setFetching();
        setData(data);
        antNotification.success('Success');
      })
      .catch((err) => {
        setError(err?.message);
        setFetching();
      });
  };

  useEffect(() => {
    if (checkedServicesKeyPairsUUID === uuid) {
      setData(checkedServicesKeyPairs?.length === 0 ? null : checkedServicesKeyPairs);
      setError(checkedServicesKeyPairsError);

      dispatch({
        type: ActorsConstants.SET_SERVICE_KEY_PAIR_UUID,
        payload: '',
      });
    }
  }, [checkedServicesKeyPairsUUID]);

  const {
    database,
    settings,
    valid_key_pair,
  } = serviceData || {};

  return (
    <Flex gap={10}>
      <BaseButton
        className={`${!error && 'button-primary-outlined'}`}
        size="small"
        danger={!!error}
        disabled={fetching}
        onClick={checkServicePair}
      >
        {fetching && 'Pending...'}
        {!fetching && !error && !serviceData && 'Check'}
        {!fetching && !error && !serviceData
                  && (
                  <BaseIcon
                    className="ml-1"
                    path={mdiKey}
                    size={1}
                  />
                  )}
        {!fetching && (error || serviceData) && 'Recheck'}
        {!fetching && (error || serviceData)
                  && (
                    <BaseIcon
                      className="ml-1"
                      path={mdiReload}
                      size={1}
                    />
                  )}
      </BaseButton>
      <div>
        {error && (
        <div
          className="text-red"
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {error}
        </div>
        )}
        {serviceData && (
        <div>
          <div className={`text-${database ? 'green' : 'orange'}`}>{`Public key ${database ? 'matches' : 'does not match'} with key in service database`}</div>
          <div className={`text-${settings ? 'green' : 'orange'}`}>{`Public key ${settings ? 'matches' : 'does not match'} with key in service settings`}</div>
          <div className={`text-${valid_key_pair ? 'green' : 'orange'}`}>{`Key pair is ${valid_key_pair ? '' : 'not'} valid`}</div>
        </div>
        )}
      </div>
    </Flex>
  );
}

function ServicesKeyPairs() {
  const dispatch = useDispatch();

  const checkedServicesKeyPairsUUID = useSelector(getCheckedServicesKeyPairsUUID);

  const { requestCheckServiceKeyPair } = useContext(ApiContext);

  const [selectedActors, setSelectedActors] = useState(new Set());

  const checkKeyPairs = async () => {
    for (const actor of selectedActors) {
      await requestCheckServiceKeyPair(actor).then((res) => {
        antNotification.success('Success');
      }).catch(() => {}).finally(() => {
        dispatch({
          type: ActorsConstants.SET_SERVICE_KEY_PAIR_UUID,
          payload: actor,
        });
      });
    }
  };

  const actionKeyPair = [{
    dataIndex: 'uuid',
    key: 'actions',
    className: 'p-2',
    ellipsis: true,
    colSpan: '3',
    // textWrap: 'word-break',
    onCell: (_, index) => ({ colSpan: 3 }),
    title: '',
    shouldCellUpdate: (record, prevRecord) => record.uuid === checkedServicesKeyPairsUUID,
    render: (cell) => (
      <div>
        <ServicesKeyPairsBtn uuid={cell} />
      </div>
    ),
  }];

  return (
    <>
      {selectedActors.size !== 0 && (
        <Row className="mb-2">
          <Col>
            <BaseButton
              type="primary"
              size="small"
              onClick={checkKeyPairs}
            >
              Check key pairs
            </BaseButton>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <ColumnsWrapperForBasicActorList
            getActorTypes={['service']}
            typeOfColumns="servicesKeyPairs"
            withCheckBox
            additionalColumns={actionKeyPair}
            getSelectedActors={setSelectedActors}
          />
        </Col>
      </Row>
    </>
  );
}

export default ServicesKeyPairs;
